import naturalSort from 'javascript-natural-sort';
import escapeStringRegexp from 'escape-string-regexp';
import lodashGet from 'lodash.get';
import {BUSINESS_DAY_LIMIT} from 'app/app/AppConstants';

$.expr[':'].containsLower = $.expr.createPseudo(arg => elem => _.getSearchRegExp(arg).test($(elem).text()));

export const get = lodashGet;

export function sortByNat(obj, value, context, {isCaseInsensitive = true, direction = 'ASC'} = {}) {
	const iterator = _.isFunction(value) ? value : function(object) { return object[value] };

	return _.pluck(_.map(obj, function(objValue, index, list) {
		return {
			index,
			value: objValue,
			criteria: iterator.call(context, objValue, index, list),
		};
	}).sort(function(left, right) {
		naturalSort.insensitive = isCaseInsensitive;

		if (direction === 'ASC') {
			return naturalSort(left.criteria, right.criteria);
		}

		return naturalSort(right.criteria, left.criteria);
	}), 'value');
}

export function sortByMultipleFieldsNat(obj, values, context, options = []) {
	const iterators = values.map(value => (_.isFunction(value) ? value : object => object[value]));

	return _.pluck(_.map(obj, function(objValue, index, list) {
		return {
			index,
			value: objValue,
			criterias: iterators.map(iterator => iterator.call(context, objValue, index, list)),
		};
	}).sort(function(left, right) {
		let iter = 0;
		let sortResult = 0;
		while (iter < values.length) {
			naturalSort.insensitive = options && options[iter] && options[iter].isCaseInsensitive || true;
			const direction = options && options[iter] && options[iter].direction || 'ASC';

			sortResult = direction === 'ASC'
				? naturalSort(left.criterias[iter], right.criterias[iter])
				: naturalSort(right.criterias[iter], left.criterias[iter]);

			if (sortResult === 0) {
				iter++;
			} else {
				break;
			}
		}

		return sortResult;
	}), 'value');
}

function insert(list, element, position) {
	const result = [...list];
	position = position < list.length && position >= 0 ? position : list.length;
	result.splice(position, 0, element);

	return result;
}

function memoizeOnce(func, hasher) {
	function memoize(...args) {
		const address = hasher ? hasher(...args) : _.first(args);
		if (memoize.cache.address !== address) {
			memoize.cache = {
				address,
				value: func(...args),
			};
		}

		return memoize.cache.value;
	}
	memoize.cache = {};

	return memoize;
}

export function getWeekStartDate(args = {}) {
	const isotime = args.isotime || '';
	const week = args.week || moment().week();
	const year = args.year || new Date().getFullYear();
	let date;

	if (isotime) {
		date = moment(isotime).day(4);
	} else {
		date = moment(dayjs(0, 'HH').year(year).day(4).week(week).format('YYYY-MM-DD'));
	}

	if (args.type === 'date') date = date.toDate();

	return date;
}

function getWeekEndDate(args = {}) {
	const isotime = args.isotime || '';
	const week = args.week || moment().week();
	const year = args.year || new Date().getFullYear();
	let date = _.getWeekStartDate({
		isotime,
		week,
		year,
		type: '',
	}).add(6, 'days');

	if (args.type === 'date') date = date.toDate();

	return date;
}

export function getBusinessDay(date = moment()) {
	return date.hour() >= 0 && date.hour() < BUSINESS_DAY_LIMIT ?
		date.clone().add(-1, 'day') :
		date;
}

function getAstronomicDay(date = moment()) {
	return date.hour() >= 0 && date.hour() < BUSINESS_DAY_LIMIT ?
		date.clone().add(1, 'day') :
		date;
}

function reverse(list = []) {
	return Array.prototype.slice.call(list, 0).reverse();
}

export const getBusinessDayStart = _.memoize((format = 'HH:mm:ss') => moment('07:00:00', format));

export const getBusinessDayEnd = _.memoize((format = 'HH:mm:ss') => moment('06:59:59', format));

function getWeekDates(args = {}) {
	const isotime = args.isotime || '';
	const week = args.week || moment().week();
	const year = args.year || new Date().getFullYear();
	let startDate;
	let endDate;

	startDate = _.getWeekStartDate({
		isotime,
		week,
		year,
		type: '',
	});

	endDate = moment(startDate).add(6, 'days');

	if (args.type === 'date') {
		startDate = startDate.toDate();
		endDate = endDate.toDate();
	}

	return {
		start: startDate,
		end: endDate,
	};
}

function getDatesIntersection(firstRange = {}, secondRange = {}, precision = 'day') {
	if (_.isEmpty(firstRange) || _.isEmpty(secondRange)) return null;

	if (
		!(firstRange.start instanceof moment) || !(firstRange.end instanceof moment) ||
		!(secondRange.start instanceof moment) || !(secondRange.end instanceof moment)
	) {
		throw new Error('Unexpected intersection arguments');
	}

	const firstRangeStart = moment.min(firstRange.start, firstRange.end).clone().startOf(precision);
	const firstRangeEnd = moment.max(firstRange.start, firstRange.end).clone().startOf(precision);

	const secondRangeStart = moment.min(secondRange.start, secondRange.end).clone().startOf(precision);
	const secondRangeEnd = moment.max(secondRange.start, secondRange.end).clone().startOf(precision);

	if (secondRangeEnd.isBefore(firstRangeStart, precision) || secondRangeStart.isAfter(firstRangeEnd, precision)) {
		return null;
	}

	return {
		start: secondRangeStart.isBetween(firstRangeStart, firstRangeEnd, precision, '[]') ? secondRangeStart : firstRangeStart,
		end:   secondRangeEnd.isBetween(firstRangeStart, firstRangeEnd, precision, '[]') ? secondRangeEnd : firstRangeEnd,
	};
}

export function secondsToMinutes(seconds, format = 'm:ss') {
	return moment().startOf('day').seconds(seconds || 0).format(format);
}

export const getScrollBarWidth = _.memoize(function getScrollBarWidth() {
	const inner = document.createElement('p');

	inner.style.width = '100%';
	inner.style.height = '200px';

	const outer = document.createElement('div');
	outer.style.position = 'absolute';
	outer.style.top = '0px';
	outer.style.left = '0px';
	outer.style.visibility = 'hidden';
	outer.style.width = '200px';
	outer.style.height = '150px';
	outer.style.overflow = 'hidden';
	outer.appendChild(inner);

	document.body.appendChild(outer);
	const w1 = inner.offsetWidth;
	outer.style.overflow = 'scroll';
	let w2 = inner.offsetWidth;
	if (w1 === w2) w2 = outer.clientWidth;
	document.body.removeChild(outer);

	return (w1 - w2);
});

/**
 * @param {object} element DOMElement
 * @returns {bool} are element children wider?
 */
const isContentOverflown = element => element.scrollWidth > element.clientWidth;

function getTipsyGravity() {
	const offset = $(this).offset();
	const width  = $(window).width();

	if (offset.left < 100) return 'nw';
	if ((width - offset.left) < 100) return 'ne';

	return 'n';
}

const getBytesUnits = _.memoize(() => ([
	['B', i18n.t('bytes.B')],
	['kB', i18n.t('bytes.kB')],
	['MB', i18n.t('bytes.MB')],
	['GB', i18n.t('bytes.GB')],
	['TB', i18n.t('bytes.TB')],
	['PB', i18n.t('bytes.PB')],
	['EB', i18n.t('bytes.EB')],
	['ZB', i18n.t('bytes.ZB')],
	['YB', i18n.t('bytes.YB')],
]));

export const formatBytes = (number, precision = 0, maxUnit = 'YB') => {
	if (typeof number !== 'number' || !Number.isFinite(number)) {
		throw new Error('Must be a number.');
	}

	const unitArr = getBytesUnits();
	const maxUnitIndex = _.findIndex(unitArr, ([unit]) => unit === maxUnit);

	let count = 0;
	while (number >= 1024 && count < maxUnitIndex) {
		number = number / 1024;
		count++;
	}

	return `${_.roundToPrecision(number, precision)} ${unitArr[count][1]}`;
};

export function isValidEmail(email) {
	const re = new RegExp('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|' +
		'(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+' +
		'[a-zA-Z]{2,}))$');

	return re.test(email);
}

export function isAuthEmailValid(email) {
	const validEmail = isValidEmail(email);
	const re = new RegExp('^[a-zA-Z0-9]+');

	return re.test(email) && validEmail;
}

export function isValidINN(inn) {
	const re = new RegExp('^(\\d{10}|\\d{12})$');

	return re.test(inn);
}

export function isValidKPP(kpp) {
	const re = new RegExp('^(\\d{4}[\\dA-Z]{2}\\d{3})$');

	return re.test(kpp);
}

export function isValidPostalCode(index) {
	const withoutSpaces = index.replace(/ /g, '');

	if (withoutSpaces.includes('.')) return false;

	return withoutSpaces.length > 0 && withoutSpaces.length < 7 && !Number.isNaN(withoutSpaces);
}

export function isValidPhone(phone) {
	const re = new RegExp('^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$');

	return re.test(phone);
}

export function isValidRegionalPhone(phone) {
	return new RegExp(/^\+*[0-9() -]{11,17}$/).test(phone.replace(/[\s()\-]+/g, ''));
}

export const getPhonePlaceholder = countryCode => `+${countryCode} (000) 000-00-00`;

export function formatCurrency(value, currency = 'rub') {
	if (!_.isFinite(value) || typeof value !== 'number') throw Error('Value must be a number');

	const quantity = value.toLocaleString();

	switch (currency) {
		case 'usd': return `$ ${quantity}`;
		case 'eur': return `€ ${quantity}`;
		case 'rub': return `${quantity} \u20BD`; // that is a ruble sign
		default: return quantity;
	}
}

export function getReleaseFormatDate(options = {}) {
	const {date, format, alternate} = options;

	let formattedDate;

	if (date.string && !date.string.match(/\d\d.\d\d.\d\d\d\d/g) && !alternate) {
		formattedDate = date.string;
	} else {
		formattedDate = _.getFormatDate(moment(date.start), date.end ? moment(date.end) : '', format);
	}

	return formattedDate;
}

export function getFileType(ext) {
	const lowerCaseExt = (ext || '').toLowerCase();
	switch (lowerCaseExt) {
		case 'jpg':
		case 'jpeg':
		case 'gif':
		case 'png':
		case 'bmp':
		case 'tif':
		case 'tiff':
			return 'image';
		case 'mp3':
		case 'm4a':
		case 'mpc':
		case 'ogg':
		case 'wav':
		case 'wma':
			return 'audio';
		case 'mov':
		case 'avi':
		case 'mkv':
		case 'flv':
		case 'wmv':
		case 'afs':
		case 'mp4':
		case 'm4v':
		case 'mpg':
			return 'video';
		case 'zip':
		case 'rar':
			return 'archive';
		case 'xls':
		case 'csv':
			return 'table';
		case 'doc':
		case 'docx':
		case 'txt':
		case 'ppt':
			return 'document';
		case 'psd':
		case 'psb':
			return 'photoshop';
		case 'pdf':
			return 'pdf';
		default:
			return null;
	}
}

function formatDate(startDate, endDate, {day = 'D', month = 'MMMM', year = 'YYYY'} = {}, formatDateRange) {
	if (!startDate && !endDate) {
		throw new TypeError('StartDate and EndDate must be not empty');
	}
	if (startDate && !(startDate instanceof moment)) {
		throw new TypeError('StartDate must be instance of Moment');
	}
	if (endDate && !(endDate instanceof moment)) {
		throw new TypeError('EndDate must be instance of Moment');
	}

	const dmyFormat = `${day} ${month} ${year}`;
	const dmFormat = `${day} ${month}`;

	if (endDate && endDate.isValid() && startDate && startDate.isValid()) {
		return formatDateRange(startDate, endDate, {day, dmFormat, dmyFormat});
	} if (startDate && startDate.isValid()) {
		return `${i18n.t('preposition.fromDate')} ${startDate.format(dmyFormat)}`;
	}

	return '';
}

function oldFormatDateRange(startDate, endDate, {day, dmFormat, dmyFormat}) {
	if (
		startDate.isSame(endDate, 'year')
		&& startDate.isSame(endDate, 'month')
		&& startDate.isSame(endDate, 'day')
	) {
		return `${startDate.format(dmyFormat)}`;
	} if (
		startDate.isSame(endDate, 'year') && startDate.isSame(endDate, 'month')
	) {
		return `${startDate.format(day)} – ${endDate.format(dmyFormat)}`;
	} if (startDate.isSame(endDate, 'year')) {
		return `${startDate.format(dmFormat)} – ${endDate.format(dmyFormat)}`;
	}

	return `${startDate.format(dmyFormat)} – ${endDate.format(dmyFormat)}`;
}

function newFormatDateRange(startDate, endDate, {day, dmFormat, dmyFormat}) {
	if (endDate.isSame(moment(), 'year')) {
		if (startDate.isSame(endDate, 'day')) {
			return startDate.format(dmFormat);
		}
		if (startDate.isSame(endDate, 'month')) {
			return `${startDate.format(day)} – ${endDate.format(dmFormat)}`;
		}
		if (startDate.isBefore(moment().subtract(2, 'year').endOf('year'))) {
			return `${startDate.format(dmyFormat)} – ${endDate.format(dmFormat)}`;
		}

		return `${startDate.format(dmFormat)} – ${endDate.format(dmFormat)}`;
	}

	if (startDate.isSame(endDate, 'day')) {
		return startDate.format(dmyFormat);
	}
	if (startDate.isSame(endDate, 'month')) {
		return `${startDate.format(day)} – ${endDate.format(dmyFormat)}`;
	}
	if (startDate.isSame(endDate, 'year')) {
		return `${startDate.format(dmFormat)} – ${endDate.format(dmyFormat)}`;
	}

	return `${startDate.format(dmyFormat)} – ${endDate.format(dmyFormat)}`;
}

export function getFormatDate(startDate, endDate, format) {
	return formatDate(startDate, endDate, format, oldFormatDateRange);
}

export function getNewFormatDate(startDate, endDate, format) {
	return formatDate(startDate, endDate, format, newFormatDateRange);
}

/**
 * @typedef {object} Locale
 * @property {string} ru
 * @property {string} en
 *
 * @param {Locale} object
 * @returns {string}
 */
export function getLocalize(object) {
	return !_.isEmpty(object)
		? object[i18n.options.lng] || object.ru || object.en
		: '';
}

export function isValidFile(path, extensionsRexExp) {
	return new RegExp(extensionsRexExp, 'i').test(_.last((path || '').split('.')));
}

export function isLinkExternal(link) {
	return link.indexOf('://') > -1;
}

export const getSearchRegExp = _.memoize(string => new RegExp(escapeStringRegexp(string || '').replace(/[её]/g, '(е|ё)'), 'i'));

const reduceWhile = (list, iterator, initAcc, predicate) => {
	let acc = initAcc;
	let index = 0;

	while (index < list.length && predicate(acc, list[index])) {
		acc = iterator(acc, list[index]);
		index++;
	}

	return acc;
};

export const capitalize = str => `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;

export const roundToPrecision = (number, precision = 2) =>
	`${Math.round(parseFloat(number) * (10 ** precision)) / (10 ** precision)}`;

export const makeGetString = (url, parameters) =>
	_.compact([url,
		_.compact(_.map(
			parameters,
			(value, key) => (
				(value || value === 0 || value === false) && `${encodeURIComponent(key)}=${encodeURIComponent(value)}`),
		)).join('&')]).join('?');

export const toCamelCase = (str = '') => str.replace(/_([a-z])/g, (match, group) => group.toUpperCase());

function makeCreateWorkerAction(type) {
	return function createWorkerAction(actionType, getPayload) {
		return args => (dispatch, getState) => {
			dispatch({
				type: actionType,
				payload: getPayload(args, getState()),
				meta: {isWorker: true, type},
			});
		};
	};
}

export const isValidURL = input => {
	const pattern = '^(https?:\\/\\/)?' +												// protocol
		'((([a-zA-Zа-яёА-ЯЁ\\d]([a-zA-Zа-яёА-ЯЁ\\d-]{0,61}[a-zA-Zа-яёА-ЯЁ\\d])*\\.)+' + // sub-domain + domain name
		'[a-zA-Zа-яёА-ЯЁ]{2,13})' +														// extension
		'|((\\d{1,3}\\.){3}\\d{1,3})' +													// OR ip (v4) address
		'|localhost)' +																	// OR localhost
		'(\\:\\d{1,5})?' +																// port
		'(\\/[a-zA-Zа-яёА-ЯЁ\\&\\d%_.~+-:@]*)*' +										// path
		'(\\?[a-zA-Zа-яёА-ЯЁ\\&\\d%_.,~+-:@=;&]*)?' +									// query string
		'(\\#[-a-zA-Zа-яёА-ЯЁ&\\d_]*)?$';												// fragment locator
	const regex = new RegExp(pattern);

	return regex.test(input);
}; /* yup string.url().validateSync has troubles with validation of localhost
	https://github.com/jquense/yup/issues/224#issuecomment-417172609 */

function createSessionStorageAction(actionType, getPayload) {
	return args => (dispatch, getState) => {
		dispatch({
			type: actionType,
			payload: getPayload(args, getState()),
			meta: {isSessionStorage: true},
		});
	};
}

export function getI18nArrayValuesWhileExists(key, transformFn = val => val, params) {
	if (!i18n.exists(key)) return [];
	if (!i18n.exists(`${key}.0`)) return [transformFn(i18n.t(key, params))];

	const accumulator = [];

	for (let index = 0; i18n.exists(`${key}.${index}`); index++) {
		accumulator.push(transformFn(i18n.t(`${key}.${index}`, params), index, key));
	}

	return accumulator;
}

/**
 * Remove leading, trailing and intermediate (all unnecessary) whitespaces from string.
 *
 * @param {string} str
 * @returns {string} trimmed string
 */
export const trimSpaces = (str = '') => str.trim().replace(/\s+/g, ' ');

export const convertBytesToGB = value => Number((value / 1024 ** 3).toFixed(1));

export const stringSearchComp = (search, item) => !item || item.toLowerCase().includes(search.trimEnd().toLowerCase());

export const selectSortComp = (optionA, optionB) => `${optionA ? optionA.label : ''}`
	.toLowerCase()
	.localeCompare(`${optionB ? optionB.label : ''}`.toLowerCase(), ['en', 'ru']);

export const mixins = {
	get,
	capitalize,
	formatBytes,
	formatCurrency,
	getBusinessDay,
	getBusinessDayStart,
	getBusinessDayEnd,
	getDatesIntersection,
	getFileType,
	getFormatDate,
	getNewFormatDate,
	getLocalize,
	getReleaseFormatDate,
	getScrollBarWidth,
	getSearchRegExp,
	getTipsyGravity,
	getAstronomicDay,
	getWeekDates,
	getWeekEndDate,
	getWeekStartDate,
	isContentOverflown,
	insert,
	isLinkExternal,
	isValidEmail,
	isAuthEmailValid,
	isValidFile,
	isValidINN,
	isValidKPP,
	isValidPostalCode,
	isValidPhone,
	isValidURL,
	makeGetString,
	memoizeOnce,
	toCamelCase,
	reduceWhile,
	reverse,
	trimSpaces,
	roundToPrecision,
	secondsToMinutes,
	sortByNat,
	sortByMultipleFieldsNat,
	makeCreateWorkerAction,
	createSessionStorageAction,
	getI18nArrayValuesWhileExists,
	convertBytesToGB,
	stringSearchComp,
	isValidRegionalPhone,
	getPhonePlaceholder,
};

_.mixin(mixins);
