import * as t from 'io-ts';
import { UserPosition } from 'app/user/UserTypes';

export type CinemaSettingsPageParams = {
	cinemaID: string;
	section: string;
}

export interface ICinemaSettingsHall {
	/** HH:mm */
	default_hall_close: string;
	/** HH:mm */
	default_hall_open: string;
	default_hall_pause: number;
	id: number;
	ticket_system_id: string;
	active: {
		value: boolean;
		updated: number;
	};
}

export interface ICinemaSettings {
	cinema_id: number;
	default_cinema_advertising_block: number;
	is_premiere: boolean;
	bar_system?: {
		posterpos_url?: string;
	};
	ticket_system: {
		title: null | string;
		url?: string;
	};
	additional_ticket_systems: Array<{
		id: string;
		url: string | null;
		is_kinokassa: boolean;
	}>;
	ticket_system_custom: string;
	/** HH:mm */
	cinema_close: string;
	/** HH:mm */
	cinema_open: string;
	/** HH:mm */
	cinema_weekend_close: string;
	/** HH:mm */
	cinema_weekend_open: string;
	occupancy_time_format: 'minutes' | 'minutesAndHours';
	new_release_period: 1 | 2 | 3;
	release_count_format: 'percents' | 'number';
	halls_settings: ICinemaSettingsHall[];
	show_release_chrono: boolean;
	schedule_with_integrated_trailers: boolean;
	videoposter: {
		currency: string | null;
	};
	kinosite?: {
		news?: { enabled: boolean };
		clients?: { enabled: boolean };
	};
}

export interface ICinemaLegal {
	address: string;
	city: string;
	inn: string;
	kpp: string;
	ogrn: string;
	owner: string;
	zip_code: string;
}

export interface IContactInfo {
	address: string;
	city: {
		en: string;
		ru: string;
	};
	email: string;
	phone: string;
}

export interface ICinemaData {
	cinema_id: number;
	cinema_legal: ICinemaLegal;
	cinema_settings: ICinemaSettings;
	contact_info: IContactInfo;
}

export type UserAccessLevel = 0 | 1 | 2 | 3 | 4;

export interface IUserEmployee {
	master: boolean;
	is_moderator: boolean;
	first_name: string;
	last_name: string;
	level: UserAccessLevel;
	user_id: string;
	role: string;
	email: string;
	phone: string;
	user_position: UserPosition['user_position'];
	user_position_key: UserPosition['user_position_key'];
	is_approved: boolean;
	accesses: {
		tms: boolean;
		videoposter: boolean;
		kinosite: boolean;
		dashboard: boolean;
	};
	cinema_rights: Array<{ cinema_id: number; level: UserAccessLevel }>;
	country_phone_code?: string;
	country_code?: string;
}

export enum Sections {
	information = 'information',
	params = 'params',
	employees = 'employees',
	subscriptions = 'subscriptions',
}

export enum AccessRights {
	none = 0,
	read = 1,
	rw = 2,
	write = 3,
	mediaplan = 4,
}

export enum EmployeeErrorCodes {
	EMAIL_ALREADY_EXISTS = 324,
	USER_IS_NOT_MASTER = 326,
	NOT_ENOUGH_CINEMA_RIGHTS = 327,
}

export interface IInvoice {
	cost: number;
	/** unix */
	date: number;
	link: string;
	number: string;
	tariff: { ru: string; en: string };
}

const SubscriptionRuntime = t.type({
	product_name: t.string,
	is_active: t.boolean,
	start: t.number,
	end: t.number,
});

export const SubscriptionsRuntime = t.array(SubscriptionRuntime);

export type Subscription = t.TypeOf<typeof SubscriptionRuntime>;

export interface ISubscriptionTariff {
	cost: number;
	tariff: number;
	title: string;
}

export interface ISubscriptionDataRequest {
	cinema_id: number;
	address: string;
	city: string;
	email: string;
	index: string;
	inn: string;
	kpp: string;
	/** 2019_07_15_19_08_41 */
	localtime: string;
	name: string;
	owner: string;
	phone: string;
	service: 'booking';
	tariff: number;
}
