import { ReactChild, ReactNode, SyntheticEvent } from 'react';
import PrmPages from 'app/new_sidebar/pages/PrmPages';
import { Moment } from 'moment';
import * as t from 'io-ts';

export type AppState = {
	mainViewNode: ReactNode;
	popupNode: ReactNode;
	isPopupClosable: boolean;
	currentURL: string[];
	headerSettings: {
		title: ReactNode;
		showHeaderSelector: boolean;
	};
	isNewSidebar: boolean;
	sidebarURLs: {
		kinoplan: string;
		ticket_system: string;
		tms: string;
		kinosite: string;
		cv: string;
		dashboard: string;
		ad_network: string;
		[PrmPages.SECTION_NAME]: string;
		videoposter: string;
		bar: string;
		crm: string;
	};
	pressedKeys: number[];
	isFetching: boolean;
	showOverlay: boolean;
	toasts: IToast[];
	error: string | Error;
	searchValue: string;
	topNotifications: {
		TMS: number;
		Monitoring: number;
		Videopanels: number;
		Kinokassa: number;
		Booking: number;
	};
	onboardingShownFeatures: string[];
	userAdvancedInfoVisibleDate: Moment | null;
	countries: ICountryLocalize[];
	countryPhoneCode: string;
	countryCode: string;
}

export interface IToast {
	id?: string;
	subtitle?: ReactChild | string;
	title?: ReactChild | string;
	delay?: number;
	type?: 'success' | 'warning' | 'error';
	onClick?: (event: SyntheticEvent) => void;
}

export type InfoPopupProps = Omit<TKComponents.IInfoPopupProps, 'onCancel' | 'onConfirm'> & {
	content?: ReactNode;
	onCancel?: () => void;
	onConfirm?: () => void;
}

export const localeProps = PropTypes.shape({
	en: PropTypes.string,
	ru: PropTypes.string,
});

export const dropdownOption = PropTypes.shape({
	value: PropTypes.any,
	title: PropTypes.node,
	isDisabled: PropTypes.bool,
});

export enum CountryOfReleaseBase {
	ru = 'RU',
	kz = 'KZ',
}

const CountryRuntime = t.type({
	alpha2: t.string,
	calling_code: t.string,
	icon_url: t.string,
	name_en: t.string,
	name_ru: t.string,
});

export const CountriesRuntime = t.array(CountryRuntime);

export type ICountries = t.TypeOf<typeof CountriesRuntime>;
export type ICountry = t.TypeOf<typeof CountryRuntime>;
export type ICountryLocalize = Pick<ICountry, 'alpha2' | 'calling_code' | 'icon_url'> & {
	title: string;
};
